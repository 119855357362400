import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  appLocalesLanguageList = [
    {
      key: 'en-US',
      Label: '(en-US) English (United States)',
    },
    {
      key: 'de-DE',
      Label: '(de-DE) Deutsch (Deutschland)',
    },
    {
      key: 'es-ES',
      Label: '(es-ES) español (España)',
    },
    {
      key: 'it-IT',
      Label: '(it-IT) italiano (Italia)',
    },
    {
      key: 'nb-NO',
      Label: '(nb-NO) Norsk bokmål (Norge)',
    },
    {
      key: 'nl-NL',
      Label: '(nl-NL) Nederlands (Nederland)',
    },
    {
      key: 'sv-SV',
      Label: '(sv-SV) Swedish (Swedish)',
    },
    {
      key: 'da-DA',
      Label: '(da-DA) Danish (Danish)',
    },
    {
      key: 'fr-FR',
      Label: '(fr-FR) français (France)',
    },
    {
      key: 'el-GR',
      Label: '(el-GR) Ελληνικά (Ελλάδα)',
    },
    {
      key: 'cs-CZ',
      Label: '(cs-CZ) čeština (Česká republika)',
    },
    {
      key: 'hu-HU',
      Label: '(hu-HU) magyar (Magyarország)',
    },
    {
      key: 'pl-PL',
      Label: '(pl-PL) polski (Polska)',
    },
    {
      key: 'pt-PT',
      Label: '(pt-PT) português (Portugal)',
    },
    // {
    //   key: 'ru-RU',
    //   Label: '(ru-RU) русский (Россия)',
    // },
    {
      key: 'sl-SL',
      Label: '(sl-SL) Slovenščina',
    },
    {
      key: 'sk-SK',
      Label: '(sk-SK) Slovenčina',
    },
    {
      key: 'tr-TR',
      Label: '(tr-TR) Türkçe (Türkiye)',
    },
    {
      key: 'pt-BR',
      Label: '(pt-BR) português (Brasil)',
    },
  ];

  getLanguage(): string {
    return (sessionStorage.getItem('lang') as string)
      ? (sessionStorage.getItem('lang') as string)
      : 'en-US';
  }
  setLanguage(language: string) {
    sessionStorage.setItem('lang', language);
  }
}
